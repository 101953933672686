import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [3,5,6,8];

export const dictionary = {
		"/": [~10],
		"/(backend)/admin": [~15,[3,4]],
		"/(backend)/admin/clients": [~16,[3,4]],
		"/(backend)/admin/users": [~17,[3,4]],
		"/(backend)/admin/users/create": [~19,[3,4]],
		"/(backend)/admin/users/[id]": [~18,[3,4]],
		"/(backend)/broker": [~20,[3]],
		"/(backend)/broker/benefits/[benefitId=objectid]/specs": [~22,[3,5]],
		"/(backend)/broker/benefits/[benefitId=objectid]/[planId=objectid]/[[section=number]]": [~21,[3,5]],
		"/(backend)/broker/clients": [~23,[3]],
		"/(backend)/broker/clients/new": [~30,[3]],
		"/(backend)/broker/clients/[clientId=objectid]": [~24,[3]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits": [~25,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/benefits/[period=benefitPeriod]": [26,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/edit": [~29,[3]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources": [~27,[3,6]],
		"/(backend)/broker/clients/[clientId=objectid]/(tabs)/resources/[resourceType=resourceType]": [~28,[3,6]],
		"/(backend)/broker/events": [~31,[3]],
		"/(backend)/broker/messages": [~32,[3]],
		"/(backend)/broker/messages/create": [~34,[3]],
		"/(backend)/broker/messages/[id]": [~33,[3]],
		"/(backend)/broker/profile": [~35,[3]],
		"/(backend)/broker/vendors": [~36,[3]],
		"/(backend)/broker/vendors/new": [~38,[3]],
		"/(backend)/broker/vendors/[id=objectid]": [~37,[3]],
		"/(auth)/forgot-password": [~11,[2]],
		"/(auth)/login": [~12,[2]],
		"/(auth)/logout": [~13,[2]],
		"/(backend)/privacy": [~39,[3]],
		"/(auth)/reset-password/[token]": [~14,[2]],
		"/(backend)/terms": [~40,[3]],
		"/(participants)/v/[benefitId=objectid]": [~41,[7]],
		"/(participants)/v/[slug]/[[code]]/authorize": [~43,[7,8]],
		"/(participants)/v/[slug]/[benefitId=objectid]": [~44,[7,8]],
		"/(participants)/v/[slug]/[...path]": [~42,[7,8,9]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';